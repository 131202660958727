import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../components/layout'
import { UStoreProvider } from '@ustore/core'
import legacyIframeHandler from '$ustoreinternal/services/legacyIframeHandler'
import './Legacy.scss'
import T from './text_translations/T';

//Using https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
const Legacy = (props) => {
  const navigate = useNavigate()

  const handleFrameMessage = (e) => {
    const msg = e.data
    if (!msg || !msg.type) {
      return
    }

    const asPath = window.location.pathname + window.location.search

    // if the message is an info request message, just call the function to get the info and post the reposnse.
    if (msg.type === '@REQUEST_UI_INFO') {
      legacyIframeHandler.onRequestScrollPosition(msg)
      return
    }

    if (msg.type === '@NG_REDIRECT' || msg.type === '@CHANGE_NG_ROUTE') {
      legacyIframeHandler.onRedirectRequested(msg, asPath)
      return
    }

    if (msg.type === '@SCROLL_PARENT_DISABLE' || msg.type === '@SCROLL_PARENT_ENABLE') {
      legacyIframeHandler.onScrollParentRequested(msg)
      return
    }

    const { messageHandled: changeRouteOrDimensionsHandled } = legacyIframeHandler.changeRouteOrDimensions(msg, asPath)
    const messageHandled = changeRouteOrDimensionsHandled || legacyIframeHandler.handleScrolling(msg)

    if (!messageHandled && !(['START', 'STATE', 'ACTION', 'PARTIAL_STATE', 'INIT_INSTANCE', '@SCROLL_ON', '@SCROLL_OFF', '@SCROLL_TO', '@CHANGE_ROUTE', '__REACT_CONTEXT_DEVTOOL_GLOBAL_HOOK_EVENT', 'webpackWarnings', 'webpackClose'].includes(msg.type))) { UStoreProvider.state.dispatch(msg) }
  }

  useEffect(() => {
    legacyIframeHandler.setNavigate = navigate
  })

  useEffect(() => {
    document.vglHrefHistory.addHref(window.location.href);
    vglInit();

    window.addEventListener('resize', legacyIframeHandler.handleResize)
    window.addEventListener('message', handleFrameMessage)
    window.addEventListener('blur', () => legacyIframeHandler.handleClickingIframe())

    legacyIframeHandler.adaptContainerToIframe()

    return () => {
      window.removeEventListener('resize', legacyIframeHandler.handleResize)
      window.removeEventListener('message', handleFrameMessage)
      window.removeEventListener('blur', () => legacyIframeHandler.handleClickingIframe())
    }
  }, [])



  // -------------------------------------------- constante - begin ------------------------------------------
  const VglSendStatus = {
    modified: 'modified', synchrone: 'synchrone'
  };
  let t = new T(window.location);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  var legacyObj = this;

  let vglFunctions = {
    vglSystem: 0,  // 0: prod, 1: dev
    vglGetIdFromArray: (valueArray) => {
      return valueArray[1];
    }, vglGetModFieldIdDropDownList: (propertyId) => {
      return 'ctl00_cphMainContent_Duc' + propertyId + '_DropDownList';
    }
  };
  // Mit diesem Statement können die Property-ID's abgefragt werden
  //
  // SELECT TOP (1000)
  // 	  DialID,
  // 	  ExternalID,
  // 	  StatusID
  //  FROM [uStore].[dbo].[Dial]
  //  where
  //    ProductId = 23996
  // 	/*and DialID = 136793*/
  // 	/*and ExternalID = 'tot_quantity'*/
  // 	/*and StatusID = 1*/
  //
  // auflage     => tot_quantity
  // ausrichtung => fe_orientation
  // umfang      => fe_pages
  // papier      => fe_paper
  // farbigkeit  => fe_color
  // fold        => fe_fold
  //
  // ctl00_cphMainContent_ctl10_Duc
  //
  let vglProductIds = {
    broschure: {
      productId: ['22353', '22711'][vglFunctions.vglSystem], fields: {
        umfang: {propertyId: ['128542', '136854'][vglFunctions.vglSystem]},
        format: {propertyId: ['128543', '136855'][vglFunctions.vglSystem]},
        ausrichtung: {propertyId: ['128718', '136862'][vglFunctions.vglSystem]},
        papier: {propertyId: ['128544', '136856'][vglFunctions.vglSystem]},
        auflage: {propertyId: ['128551', '136860'][vglFunctions.vglSystem]}
      }
    }, visitenkartenSamen: {
      productId: ['22407', '22703'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['129896', '136793'][vglFunctions.vglSystem]},
        pages: {propertyId: ['129904', '136799'][vglFunctions.vglSystem]},
        farbigkeit: {propertyId: ['129901', '136797'][vglFunctions.vglSystem]}
      }
    }, visitenkartenC2c: {
      productId: ['22471', '22704'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['130463', '136807'][vglFunctions.vglSystem]},
        pages: {propertyId: ['130469', '136813'][vglFunctions.vglSystem]},
        farbigkeit: {propertyId: ['130467', '136811'][vglFunctions.vglSystem]}
      }
    }, briefpapier: {
      productId: ['23934', '22732'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['135789', '136871'][vglFunctions.vglSystem]},
        pages: {propertyId: ['135795', '136877'][vglFunctions.vglSystem]},
        farbigkeit: {propertyId: ['135793', '136875'][vglFunctions.vglSystem]}
      }
    }, korrespondenzkarten: {
      productId: ['23933', '22733'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['135776', '136884'][vglFunctions.vglSystem]},
        pages: {propertyId: ['135782', '136890'][vglFunctions.vglSystem]},
        farbigkeit: {propertyId: ['135780', '136888'][vglFunctions.vglSystem]}
      }
    }, postkartenSamen: {
      productId: ['22506', '22707'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['130502', '136820'][vglFunctions.vglSystem]}
      }
    }, postkartenC2c: {
      productId: ['22508', '22708'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['130544', '136833'][vglFunctions.vglSystem]},
        farbigkeit: {propertyId: ['130548', '136837'][vglFunctions.vglSystem]}
      }
    }, flyerC2c: {
      productId: ['22349', '22710'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['136637', '136939'][vglFunctions.vglSystem]},
        format: {propertyId: ['136632', '136934'][vglFunctions.vglSystem]},
        ausrichtung: {propertyId: ['136639', '136941'][vglFunctions.vglSystem]},
        papier: {propertyId: ['136633', '136935'][vglFunctions.vglSystem]},
        farbigkeit: {propertyId: ['136643', '136945'][vglFunctions.vglSystem]}
      }
    }, flyerFalz: {
      productId: ['23932', '22737'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['135763', '136898'][vglFunctions.vglSystem]},
        format: {propertyId: ['135758', '136893'][vglFunctions.vglSystem]},
        ausrichtung: {propertyId: ['135765', '136900'][vglFunctions.vglSystem]},
        papier: {propertyId: ['135759', '136894'][vglFunctions.vglSystem]}
      }
    }, flyerFalz6: {
      productId: ['23977', '23996'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['137001', '136987'][vglFunctions.vglSystem]},
        format: {propertyId: ['136996', '136982'][vglFunctions.vglSystem]},
        ausrichtung: {propertyId: ['137003', '136989'][vglFunctions.vglSystem]},
        papier: {propertyId: ['136997', '136983'][vglFunctions.vglSystem]},
        fold: {propertyId: ['137070', '137072'][vglFunctions.vglSystem]}
      }
    }, plakat: {
      productId: ['24046', '23999'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['137229', '136925'][vglFunctions.vglSystem]},
        format: {propertyId: ['137224', '136920'][vglFunctions.vglSystem]},
        papier: {propertyId: ['137225', '136921'][vglFunctions.vglSystem]}
      }
    }, tischset: {
      productId: ['24047', '24017'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['137216', '137052'][vglFunctions.vglSystem]}
      }
    }, popcorn: {
      productId: ['24087', '24059'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['137714', '137460'][vglFunctions.vglSystem]}
      }
    }, klatschfaecher: {
      productId: ['24088', '24062'][vglFunctions.vglSystem], fields: {
        auflage: {propertyId: ['137727', '137492'][vglFunctions.vglSystem]},
        farbigkeit: {propertyId: ['137731', '137496'][vglFunctions.vglSystem]}
      }
    }
  };

  function vglBuildPaperObj(isGestrrichen = false, weight = '', status = 'enabled', visible = undefined) {
    let paper = '';
    if (isGestrrichen) {
      paper = {value: t.get().label_gestrichen + ' ' + weight, status: status, visible, value2: 'Pureprint matt'};
    } else {
      paper = {value: t.get().label_ungestrichen + ' ' + weight, status: status, visible, value2: 'Lessebo 1.3 Rough White'};
    }
    return paper;
  }

  let vglPaper = {
    p0001: (visible, status) => {return vglBuildPaperObj(false, '170g/m²', status, visible)},
    p0002: (visible, status) => {return vglBuildPaperObj(true, '170g/m²', status, visible)},
    p0003: (visible, status) => {return vglBuildPaperObj(false, '240g/m²', status, visible)},
    p0004: (visible, status) => {return vglBuildPaperObj(false, '120g/m²', status, visible)},
    p0005: (visible, status) => {return vglBuildPaperObj(true, '250g/m²', status, visible)},
    p0006: (visible, status) => {return vglBuildPaperObj(true, '135g/m²', status, visible)},
    p0007: (visible, status) => {return vglBuildPaperObj(true, '170g/m²', status, visible)},
    p0008: (visible, status) => {return vglBuildPaperObj(true, '250g/m²', status, visible)},
    p0009: (visible, status) => {return vglBuildPaperObj(true, '170g/m²', status, visible)},
    p0010: (visible, status) => {return vglBuildPaperObj(true, '400g/m²', status, visible)},
    p0011: (visible, status) => {return vglBuildPaperObj(false, '150g/m²', status, visible)},
    p0012: (visible, status) => {return vglBuildPaperObj(false, '200g/m²', status, visible)},
    p0013: (visible, status) => {return vglBuildPaperObj(false, '300g/m²', status, visible)},
    p0014: (visible, status) => {return vglBuildPaperObj(false, '150g/m²', status, visible)},
    p0015: (visible, status) => {return vglBuildPaperObj(false, '200g/m²', status, visible)},
    p0016: (visible, status) => {return vglBuildPaperObj(false, '300g/m²', status, visible)},
    p0017: (visible, status) => {return vglBuildPaperObj(false, '200g/m²', status, visible)},
    p0018: (visible, status) => {return vglBuildPaperObj(false, '240g/m²', status, visible)},
    p0019: (visible, status) => {return vglBuildPaperObj(true, '150g/m²', status, visible)},
    p0020: (visible, status) => {return vglBuildPaperObj(true, '170g/m²', status, visible)},
    p0021: (visible, status) => {return vglBuildPaperObj(true, '250g/m²', status, visible)},
    p0022: (visible, status) => {return vglBuildPaperObj(true, '400g/m²', status, visible)},
    p0023: (visible, status) => {return vglBuildPaperObj(false, '240g/m²', status, visible)},
  };

  // -------------------------------------------- constante - end --------------------------------------------

  // -----------------------------------------------------------------------------------------------------------
  // -------------------------------------------- Property-Templates -------------------------------------------
  // -----------------------------------------------------------------------------------------------------------

  /**
   *
   * @param propertyId -> z.B. vglProductIds.flyerFalz.format.propertyId
   * @param generateFieldRadioFunction
   * @param title
   * @param defaultValue
   * @param options
   * @param visible
   * @param helpText
   * @param helpTextLink
   * @constructor
   */
  var BaseConfigFieldTemplateRadio = function(propertyId, generateFieldRadioFunction, title, defaultValue, options, visible, helpText, helpTextLink) {
    if (propertyId !== undefined) {
      this.propertyId = propertyId;
      this.modificationFieldDomId = vglFunctions.vglGetModFieldIdDropDownList(propertyId);
      this.title = title;
      this.defaultValue = defaultValue;
      this.options = options;
      if (visible !== undefined) {
        this.visible = visible;
      }
      if (helpText !== undefined) {
        this.helpText = helpText;
      }
      if (helpTextLink !== undefined) {
        this.helpTextLink = helpTextLink;
      }
    } else {
      this.propertyId = null;
      this.modificationFieldDomId = null;
      this.title = '';
      this.defaultValue = '';
      this.options = {};
    }
    this.initialisationFunction = function(propertyDomElement) {
      return generateFieldRadioFunction(propertyDomElement);
    };
    this.getValueFromDom = function(propertyDomElement) {
      return generateFieldRadioFunction.getValueFromDom(propertyDomElement);
    };
    this.setValueOnDom = function(propertyDomElement, value) {
      generateFieldRadioFunction.setValueOnDom(propertyDomElement, value);
    };
    this.setVisibleOnDom = function(propertyDomElement, value, visible) {
      generateFieldRadioFunction.setVisibleOnDom(propertyDomElement, value, visible);
    };
    this.setAccessRightsOnDom = function(propertyDomElement) {
      generateFieldRadioFunction.setAccessRightsOnDom(propertyDomElement);
    };
  };

  /**
   *
   * @param propertyId -> z.B. vglProductIds.flyerFalz.format.propertyId
   * @param generateFieldRadioFunction
   * @param additionalOptions
   * @param _options
   * @constructor
   */
  var DefaulFormat = function(propertyId, generateFieldRadioFunction, additionalOptions, _options = null) {
    var options;
    var defaultFormat = 'A4';
    if (_options !== null) {
      options = _options;
      defaultFormat = _options[Object.keys(_options)[0]].value;
    } else {
      options = {
        'A4': {value: 'A4', status: 'enabled'},
        'A5': {value: 'A5', status: 'enabled'},
        'A6': {value: 'A6', status: 'enabled'}
      };
    }
    if (additionalOptions !== undefined && additionalOptions !== null) {
      for (let index in additionalOptions) {
        options[index] = additionalOptions[index];
      }
    }
    BaseConfigFieldTemplateRadio.call(this, propertyId, generateFieldRadioFunction, t.get().label_Format,
      defaultFormat, options);
  };
  DefaulFormat.prototype = new BaseConfigFieldTemplateRadio();
  DefaulFormat.prototype.constructor = DefaulFormat;

  /**
   *
   * @param propertyId -> z.B. vglProductIds.flyerFalz.format.propertyId
   * @param generateFieldRadioFunction
   * @constructor
   */
  var DefaulOrientation = function(propertyId, generateFieldRadioFunction) {
    var options = {
      'V': {value: t.get().label_Hochformat, status: 'enabled'},
      'H': {value: t.get().label_Querformat, status: 'enabled'}
    };
    BaseConfigFieldTemplateRadio.call(this, propertyId, generateFieldRadioFunction, t.get().label_Ausrichtung, 'V',
      options);
  };
  DefaulOrientation.prototype = new BaseConfigFieldTemplateRadio();
  DefaulOrientation.prototype.constructor = DefaulOrientation;

  /**
   *
   * @param propertyId -> z.B. vglProductIds.flyerFalz.format.propertyId
   * @param generateFieldRadioFunction
   * @constructor
   */
  var DefaulQty250To5000 = function(propertyId, generateFieldRadioFunction) {
    var options = {
      '250': {value: '250', status: 'enabled'},
      '500': {value: '500', status: 'enabled'},
      '1000': {value: '1000', status: 'enabled'},
      '1500': {value: '1500', status: 'enabled'},
      '2000': {value: '2000', status: 'enabled'},
      '3000': {value: '3000', status: 'enabled'},
      '5000': {value: '5000', status: 'enabled'}
    };
    BaseConfigFieldTemplateRadio.call(this, propertyId, generateFieldRadioFunction, t.get().label_Auflage, '250',
      options);
  };
  DefaulQty250To5000.prototype = new BaseConfigFieldTemplateRadio();
  DefaulQty250To5000.prototype.constructor = DefaulQty250To5000;

  /**
   *
   * @param propertyId -> z.B. vglProductIds.flyerFalz.format.propertyId
   * @param generateFieldRadioFunction
   * @param mode
   * @constructor
   */
  var DefaulQty500To10000 = function(propertyId, generateFieldRadioFunction, mode) {
    var options = {};
    switch (mode) {
      case 'smal':
        options = {
          '500': {value: '500', status: 'enabled'},
          '1000': {value: '1000', status: 'enabled'},
          '2000': {value: '2000', status: 'enabled'},
          '3000': {value: '3000', status: 'enabled'},
          '10000': {value: '10000', status: 'enabled'}
        };
        break;
      default:
        options = {
          '500': {value: '500', status: 'enabled'},
          '1000': {value: '1000', status: 'enabled'},
          '1500': {value: '1500', status: 'enabled'},
          '2000': {value: '2000', status: 'enabled'},
          '2500': {value: '2500', status: 'enabled'},
          '3000': {value: '3000', status: 'enabled'},
          '5000': {value: '5000', status: 'enabled'},
          '10000': {value: '10000', status: 'enabled'}
        };
        break;
    }
    BaseConfigFieldTemplateRadio.call(this, propertyId, generateFieldRadioFunction, t.get().label_Auflage, '500',
      options);
  };
  DefaulQty500To10000.prototype = new BaseConfigFieldTemplateRadio();
  DefaulQty500To10000.prototype.constructor = DefaulQty500To10000;

  /**
   *
   * @param propertyId -> z.B. vglProductIds.flyerFalz.format.propertyId
   * @param generateFieldRadioFunction
   * @constructor
   */
  var DefaulQty250To10000 = function(propertyId, generateFieldRadioFunction) {
    var options = {
      '250': {value: '250', status: 'enabled'},
      '500': {value: '500', status: 'enabled'},
      '1000': {value: '1000', status: 'enabled'},
      '1500': {value: '1500', status: 'enabled'},
      '2000': {value: '2000', status: 'enabled'},
      '2500': {value: '2500', status: 'enabled'},
      '3000': {value: '3000', status: 'enabled'},
      '5000': {value: '5000', status: 'enabled'},
      '10000': {value: '10000', status: 'enabled'}
    };

    BaseConfigFieldTemplateRadio.call(this, propertyId, generateFieldRadioFunction, t.get().label_Auflage, '250',
      options);
  };
  DefaulQty250To10000.prototype = new BaseConfigFieldTemplateRadio();
  DefaulQty250To10000.prototype.constructor = DefaulQty250To10000;

  /**
   *
   * @param propertyId -> z.B. vglProductIds.flyerFalz.format.propertyId
   * @param generateFieldRadioFunction
   * @param options
   * @param defaultValue
   * @param helpText
   * @constructor
   */
  var DefaulColor = function(propertyId, generateFieldRadioFunction, options, defaultValue, helpText) {
    if (options === undefined) {
      options = {
        '4/0': {value: t.get().label_Einseitig, status: 'enabled'},
        '4/4': {value: t.get().label_Doppelseitig, status: 'enabled'}
      };
    }
    if (defaultValue === undefined) {
      defaultValue = '4/0';
    }
    BaseConfigFieldTemplateRadio.call(this, propertyId, generateFieldRadioFunction, t.get().label_Seitenaufdruck,
      defaultValue, options, undefined, helpText);
  };
  DefaulColor.prototype = new BaseConfigFieldTemplateRadio();
  DefaulColor.prototype.constructor = DefaulColor;

  /**
   *
   * @param propertyId -> z.B. vglProductIds.flyerFalz.format.propertyId
   * @param generateFieldRadioFunction
   * @param options
   * @param visible
   * @constructor
   */
  var DefaulPages = function(propertyId, generateFieldRadioFunction, options, visible) {
    if (options === undefined) {
      options = {
        '1': {value: '1', status: 'enabled'},
        '2': {value: '2', status: 'enabled'},
        '3': {value: '3', status: 'enabled'},
        '4': {value: '4', status: 'enabled'},
        '5': {value: '5', status: 'enabled'},
        '6': {value: '6', status: 'enabled'},
        '7': {value: '7', status: 'enabled'},
        '8': {value: '8', status: 'enabled'},
        '9': {value: '9', status: 'enabled'},
        '10': {value: '10', status: 'enabled'},
        '11': {value: '11', status: 'enabled'},
        '12': {value: '12', status: 'enabled'},
        '13': {value: '13', status: 'enabled'},
        '14': {value: '14', status: 'enabled'},
        '15': {value: '15', status: 'enabled'},
        '16': {value: '16', status: 'enabled'},
        '17': {value: '17', status: 'enabled'},
        '18': {value: '18', status: 'enabled'},
        '19': {value: '19', status: 'enabled'},
        '20': {value: '20', status: 'enabled'}
      };
    }
    BaseConfigFieldTemplateRadio.call(this, propertyId, generateFieldRadioFunction, t.get().label_Pages, '1', options,
      visible);
  };
  DefaulPages.prototype = new BaseConfigFieldTemplateRadio();
  DefaulPages.prototype.constructor = DefaulPages;

  /**
   *
   * @param propertyId -> z.B. vglProductIds.flyerFalz.format.propertyId
   * @param generateFieldRadioFunction
   * @param foldHelpTextLink
   * @constructor
   */
  var FoldF06 = function(propertyId, generateFieldRadioFunction, foldHelpTextLink) {
    var options = {
      'F06-1': {value: t.get().label_fold_f06_1, status: 'enabled'},
      'F06-5': {value: t.get().label_fold_f06_5, status: 'enabled'}
    };
    BaseConfigFieldTemplateRadio.call(this, propertyId, generateFieldRadioFunction, t.get().label_fold, 'F06-1',
      options, undefined, undefined, foldHelpTextLink);
  };
  FoldF06.prototype = new BaseConfigFieldTemplateRadio();
  FoldF06.prototype.constructor = FoldF06;

  // ---------------------------------------- Property-Templates - begin -------------------------------------

  // -----------------------------------------------------------------------------------------------------------
  // ------------------------------------------------ Schaltlogik ----------------------------------------------
  // -----------------------------------------------------------------------------------------------------------

  var VglHrefHistory = function() {
    this.vglHrefHistory = [];
    this.productId = 0;

    this.addHref = function(href) {
      if (this.vglHrefHistory.length > 5) {
        this.vglHrefHistory.shift();
      }
      this.vglHrefHistory.push(href);
    };
    this.getSecondLast = function() {
      if (this.vglHrefHistory.length >= 2) {
        return this.vglHrefHistory[this.vglHrefHistory.length - 2];
      }
      return '';
    };
    this.getProductId = function() {
      return this.productId;
    };
    this.clear = function() {
      this.vglHrefHistory = [];
    };
    this.setProductId = function(productId) {
      this.productId = productId;
    };
  };
  var mainLoopInterval = null;
  if (document.vglHrefHistory === undefined) {
    document.vglHrefHistory = new VglHrefHistory();
  }

  /**
   * frontend-element
   */
  var VglPropertyDomElement = function(title, initialisationFunction, setValueOnDomFunction, setVisibleOnDomFunction, getValueFromDomFunction, setAccessRightsOnDom, visible) {
    this.title = title;
    this.value = '';
    this.domElement = null;
    this.propertyField = null;
    this.visible = visible;

    this.setValue = function(value, doNotDelegateSetValue) {
      if ((doNotDelegateSetValue === undefined || doNotDelegateSetValue === false) && this.propertyField !==
        undefined && this.propertyField !== null) {

        this.propertyField.setValue(value);
      }
      this.value = value;
    };
    this.getValueFromDom = function() {
      if (typeof getValueFromDomFunction === 'function') {
        return getValueFromDomFunction(this);
      }
      return null;
    };
    this.setValueOnDom = function(value) {
      if (typeof setValueOnDomFunction === 'function') {
        setValueOnDomFunction(this, value);
      }
    };
    this.setVisibleOnDom = function(value, visible) {
      if (typeof setVisibleOnDomFunction === 'function') {
        setVisibleOnDomFunction(this, value, visible);
      }
    };
    this.setAccessRightsOnDom = function() {
      if (typeof setAccessRightsOnDom === 'function') {
        setAccessRightsOnDom(this);
      }
    };
    this.init = function() {
      if (typeof initialisationFunction === 'function') {
        this.domElement = initialisationFunction(this);
      }
    };
  };

  /**
   * schattenobjekt
   */
  var VglPropertyField = function(propertyId, defaultValue, options, properties, helpText, helpTextLink) {
    if (options !== undefined) {
      this.options = options;
    } else {
      this.options = {};
    }
    if (properties !== undefined) {
      this.properties = properties;
    } else {
      this.properties = {};
    }
    this.defaultValue = defaultValue;
    this.propertyId = propertyId;
    this.status = VglSendStatus.synchrone;
    this.value = defaultValue;
    this.domElement = null; // vom typ VglPropertyDomElement
    this.propertyEntity = null;
    this.helpText = helpText;
    this.helpTextLink = helpTextLink;

    this.addPropertyDomElement = function(domElement) {
      domElement.propertyField = this;
      this.domElement = domElement;
      return this;
    };
    this.getValue = function() {
      return this.value;
    };
    this.init = function() {
      this.domElement.init();
    };
    this.setValue = function(value, withoutFireEvent) {
      if (this.value !== value) {
        this.domElement.setValue(value, true);
        this.value = value;
        this.status = VglSendStatus.modified;
        if (withoutFireEvent === undefined || !withoutFireEvent) {
          this.propertyEntity.fireChange(this);
        }
      }
    };
  };

  /**
   * schattenobjekt
   */
  var VglPropertyEntity = function() {
    this.fields = [];
    this.onValueChangeListeners = [];
    this.onInitDoneListeners = [];

    this.addField = function(propertyField) {
      propertyField.propertyEntity = this;
      this.fields.push(propertyField);
    };
    this.addOnValueChangeListener = function(onValueChangeListener) {
      this.onValueChangeListeners.push(onValueChangeListener);
    };
    this.addOnInitDoneListener = function(onInitDoneListener) {
      this.onInitDoneListeners.push(onInitDoneListener);
    };
    this.fireChange = function(event) {
      for (var i = 0; i < this.onValueChangeListeners.length; i++) {
        var onValueChangeListener = this.onValueChangeListeners[i];
        onValueChangeListener(event);
      }
    };
    this.fireInitDone = function(event) {
      for (var i = 0; i < this.onInitDoneListeners.length; i++) {
        var onInitDoneListener = this.onInitDoneListeners[i];
        onInitDoneListener(event);
      }
    };
    this.init = function() {
      for (var i = 0; i < this.fields.length; i++) {
        var field = this.fields[i];
        field.init();
      }
      this.fireInitDone(this);
    };
    this.getField = function(propertyId) {
      for (var i = 0; i < this.fields.length; i++) {
        var field = this.fields[i];

        if (field.propertyId === propertyId) {
          return field;
        }
      }
      return null;
    };
    this.getValue = function(propertyId, preset) {
      var field = this.getField(propertyId);
      var value = '';
      if (field !== null) {
        value = field.value;
      }
      return value;
    };
    this.hasModifiedFields = function() {
      for (var i = 0; i < this.fields.length; i++) {
        var field = this.fields[i];
        if (field.status === VglSendStatus.modified) {
          return true;
        }
      }
      return false;
    };
    this.setStatus = function(status) {
      for (var i = 0; i < this.fields.length; i++) {
        var field = this.fields[i];
        field.status = status;
      }
    };
  };

  /**
   * dialog-steuerung
   */
  var VglPropertyWriterDialog = function(dialogOpenElementPropertyId, onInprogressListener) {
    var self = this;
    var dialogOpenElement = null;
    var values = [];
    this.status = 'closed'; // open, closed
    this.innerIframe = null;
    this.innerDocumentObj = null;
    this.onInprogressListener = onInprogressListener;
    this.tries = 0;
    this.reset = false;

    var openDialog = function(isOpenCallback, elseFunction) {
      if (self.status === 'closed') {
        var documentObj = VglFinder.getMainIframeDocument();

        dialogOpenElement = findOpenElement();
        if (dialogOpenElement !== null) {
          dialogOpenElement.click();
          var clickIsActive = true;
          setTimeout(function() {
            self.innerIframe = documentObj.querySelector('.PrintPropertiesControl iframe');
            self.innerIframe.addEventListener('load', function() {
              if (clickIsActive) {
                clickIsActive = false;
                if (self.status === 'closed') {
                  self.status = 'open';
                  var innerWindow = self.innerIframe.contentWindow;
                  self.innerDocumentObj = innerWindow.document;

                  isOpenCallback();
                }
              }
            });
          }, 1);
        } else {
          elseFunction();
        }
      } else {
        isOpenCallback();
      }
    };
    var closeDialog = function() {
      if (self.status === 'open') {
        self.status = 'closed';
        var dialogCloseElement = findCloseElement();
        dialogCloseElement.click();
        setTimeout(function() {
          self.onInprogressListener('ready');
        }, 1000);
      } else {
        self.onInprogressListener('ready');
      }
    };
    var findOpenElement = function() {
      var documentObj = VglFinder.getMainIframeDocument();
      var dialProp = documentObj.querySelectorAll('div[class="PropertyItem"]');
      if (dialProp !== undefined && dialProp !== null && dialProp.length > 0) {
        for (var i = 0; i < dialProp.length; i++) {
          var element = dialProp[i];
          if (element.attributes.length) {
            for (var index in element.attributes) {
              if (element.attributes.hasOwnProperty(index)) {
                var element2 = element.attributes[index];
                if (element2.nodeName === 'dialid' && element2.value === dialogOpenElementPropertyId) {
                  return element.children[0].children[0];
                }
              }
            }
          }
        }
      }
      return null;
    };
    var findCloseElement = function() {
      var popupFooter = self.innerDocumentObj.querySelectorAll('div[class="popupFooter"');
      return popupFooter[0].children[0].children[0];
    };
    this.setOnInprogressListener = function(onInprogressListener) {
      this.onInprogressListener = onInprogressListener;
    };
    this.setValue = function(modificationFieldDomId, value) {
      values.push({modificationFieldDomId: modificationFieldDomId, value: value});
    };
    this.flush = function() {
      this.onInprogressListener('bussy');

      openDialog(function() {
        for (var index in values) {
          if (values.hasOwnProperty(index)) {
            var value = values[index];

            var fieldElement = self.innerDocumentObj.getElementById(value.modificationFieldDomId);
            fieldElement.value = value.value;
          }
        }
        closeDialog(); // das bewirkt, dass die daten gesendet werden.
        values = [];
      }, function() {
        if (self.reset === false) {
          if (self.tries < 5) {
            self.tries++;
            setTimeout(function() {
              self.flush();
            }, 1000);
          } else {
            self.tries = 0;
          }
        } else {
          self.tries = 0;
          self.reset = false;
        }
      });
    };
  };

  /**
   * diese class schreibt daten zum server (fasade) -> verwendet VglPropertyWriterDialog oder eine andere implementation mit schnittstelle setValue, flush
   */
  var VglPropertyWriterManager = function(propertyEntity, propertyWriterDialog, productId) {
    this.productId = productId;
    this.propertyEntity = propertyEntity;
    this.propertyWriterDialog = propertyWriterDialog;

    this.appendPropertiesToRootNode = function(rootNode) {
      for (var i = 0; i < propertyEntity.fields.length; i++) {
        var field = propertyEntity.fields[i];
        rootNode.appendChild(field.domElement.domElement);
      }
    };
    this.onChange = function(event) {
      doSend();
    };
    //propertyEntity.addOnValueChangeListener(onChange);
    var doSend = function() {
      var somthingToSend = false;
      for (var i = 0; i < propertyEntity.fields.length; i++) {
        var field = propertyEntity.fields[i];
        if (field.status === VglSendStatus.modified) {
          propertyWriterDialog.setValue(field.properties.modificationFieldDomId, field.value);
          field.status = VglSendStatus.synchrone;
          somthingToSend = true;
        }
      }
      if (somthingToSend) {
        propertyWriterDialog.flush();
      }
    };
    this.forceSendAll = function() {
      propertyEntity.setStatus(VglSendStatus.modified);
      doSend();
    };
    this.init = function() {
      propertyEntity.init();
    };
    this.send = function(event) {
      doSend();
    };
  };

  /**
   * diese class kontrolliert die Werte in der Entity
   */
  var VglPropertyRuleManager = function(_productId) {
    var productId = _productId;

    var selectField = function(propertyEntity, fieldTitle) {
      for (var i = 0; i < propertyEntity.fields.length; i++) {
        var searchedField = propertyEntity.fields[i];

        if (searchedField.domElement.title === fieldTitle) {
          return searchedField;
        }
      }
    };

    var takePropertyFromPropertyEntity = function(propertyEntity, fieldTitle) {
      for (let i = 0; i < propertyEntity.fields.length; i++) {
        let field = propertyEntity.fields[i];
        if (field.domElement.title === fieldTitle) {
          return field;
        }
      }
      return null;
    };

    var setOptionsAndValuesBroschuere01 = function(propertyEntity) {
      var i = 0;
      for (i = 0; i < propertyEntity.fields.length; i++) {
        var fieldMaster = propertyEntity.fields[i];

        if (fieldMaster.domElement.title === t.get().label_Umfang) {
          var umfang8To16 = (fieldMaster.value === '8' || fieldMaster.value === '12' || fieldMaster.value === '16');
          var fieldToCompare = null;
          var option = null;
          var key = null;
          var value = null;
          for (i = 0; i < propertyEntity.fields.length; i++) {
            fieldToCompare = propertyEntity.fields[i];
            if (fieldToCompare.domElement.title === t.get().label_Papier) {
              for (key in fieldToCompare.options) {
                if (fieldToCompare.options.hasOwnProperty(key)) {
                  option = fieldToCompare.options[key];

                  // optionen setzen
                  if ((umfang8To16 && (key === 'P0001' || key === 'P0002')) ||
                    (!umfang8To16 && key !== 'P0001' && key !== 'P0002')) {
                    option.status = 'enabled';
                  } else {
                    option.status = 'disabled';
                  }
                }
              }
              fieldToCompare.domElement.setAccessRightsOnDom();
              // werte setzen
              if (umfang8To16 && fieldToCompare.value !== 'P0001' && fieldToCompare.value !== 'P0002') {
                value = 'P0001';
                fieldToCompare.setValue(value, true);
                fieldToCompare.domElement.setValueOnDom(value);
              }
              if (!umfang8To16 && (fieldToCompare.value === 'P0001' || fieldToCompare.value === 'P0002')) {
                value = 'P0004, P0003';
                fieldToCompare.setValue(value, true);
                fieldToCompare.domElement.setValueOnDom(value);
              }
              break;
            }
          }
          break;
        }
      }
    };

    var setOptionsAndValuesFlyerC2C01 = function(propertyEntity) {
      var i = 0;
      for (i = 0; i < propertyEntity.fields.length; i++) {
        var fieldFormat = propertyEntity.fields[i];

        if (fieldFormat.domElement.title === t.get().label_Format) {
          for (i = 0; i < propertyEntity.fields.length; i++) {
            var fieldPapier = propertyEntity.fields[i];
            if (fieldPapier.domElement.title === t.get().label_Papier) {
              // werte setzen
              setOptionsAndValuesFlyerC2C01_togglePapier(fieldFormat, fieldPapier, fieldFormat.value, 'P0011',
                'P0014');
              setOptionsAndValuesFlyerC2C01_togglePapier(fieldFormat, fieldPapier, fieldFormat.value, 'P0018',
                'P0023');
              setOptionsAndValuesFlyerC2C01_togglePapier(fieldFormat, fieldPapier, fieldFormat.value, 'P0013',
                'P0016');
              break;
            }
          }
          break;
        }
      }
    };
    var setOptionsAndValuesFlyerC2C01_togglePapier = function(fieldFormat, fieldPapier, formatToCompre, origPapier, newPapier) {
      if (fieldFormat.value === 'A6/5') {
        if (fieldPapier.value === origPapier) {
          fieldPapier.setValue(newPapier, true);
          fieldPapier.domElement.setValueOnDom(newPapier);
        }
        fieldPapier.domElement.setVisibleOnDom(newPapier, true);
        fieldPapier.domElement.setVisibleOnDom(origPapier, false);
      } else {
        if (fieldPapier.value === newPapier) {
          fieldPapier.setValue(origPapier, true);
          fieldPapier.domElement.setValueOnDom(origPapier);
        }
        fieldPapier.domElement.setVisibleOnDom(origPapier, true);
        fieldPapier.domElement.setVisibleOnDom(newPapier, false);
      }
    };

    var setOptionsAndValuesFlyer06Seitig = function(propertyEntity) {
      var key = null;
      var value = null;

      var fieldFormat = getField(propertyEntity, t.get().label_Format);
      if (fieldFormat !== null) {

        var fieldOrientation = getField(propertyEntity, t.get().label_Ausrichtung);
        if (fieldOrientation !== null) {

          // optionen setzen
          for (key in fieldOrientation.options) {
            if (fieldOrientation.options.hasOwnProperty(key)) {
              if (key === 'V' || fieldFormat.value !== 'A4') {
                fieldOrientation.options[key].status = 'enabled';
              } else {
                fieldOrientation.options[key].status = 'disabled';
              }
            }
          }
          fieldOrientation.domElement.setAccessRightsOnDom();

          // werte setzen
          if (fieldFormat.value === 'A4') {
            value = 'V';
            fieldOrientation.setValue(value, true);
            fieldOrientation.domElement.setValueOnDom(value);
          }
        }
      }
    };

    var getField = function(propertyEntity, fieldTitle) {
      var fieldToFind = null;
      for (var i = 0; i < propertyEntity.fields.length; i++) {
        var fieldFromList = propertyEntity.fields[i];
        if (fieldFromList.domElement.title === fieldTitle) {
          fieldToFind = fieldFromList;
          break;
        }
      }
      return fieldToFind;
    };

    this.validate = function(propertyEntityOrField) {
      var propertyEntity = null;
      if (propertyEntityOrField instanceof VglPropertyField) {
        propertyEntity = propertyEntityOrField.propertyEntity;
      } else {
        propertyEntity = propertyEntityOrField;
      }
      // --------------------------------------------- define - begin --------------------------------------------
      switch (productId) {
        case Number(vglProductIds.broschure.productId):
          setOptionsAndValuesBroschuere01(propertyEntity);
          break;
        case Number(vglProductIds.flyerC2c.productId):
          setOptionsAndValuesFlyerC2C01(propertyEntity);
          break;
        case Number(vglProductIds.flyerFalz6.productId):
          setOptionsAndValuesFlyer06Seitig(propertyEntity);
          break;
        default:
          break;
      }
      // ---------------------------------------------- define - end ---------------------------------------------
    };
  };

  var VglFinder = function() {
  };
  VglFinder.getMainIframe = function() {
    var iframe = document.getElementById('legacy-iframe');
    return iframe;
  };
  VglFinder.getMainIframeDocument = function() {
    return VglFinder.getMainIframe().contentWindow.document;
  };

  var VglGlobalfunctions = function() {
  };
  VglGlobalfunctions.addStringFromAttributeString = function(attributeString, value) {
    if (attributeString !== undefined && attributeString !== null && attributeString !== '') {
      var attributeStringParts = attributeString.split(' ');
      var found = false;
      for (var i = 0; i < attributeStringParts.length; i++) {
        var attributeStringPart = attributeStringParts[i];

        if (attributeStringPart === value) {
          found = true;
          break;
        }
      }
      if (!found && value !== '') {
        attributeStringParts.push(value);
      }
      attributeString = attributeStringParts.join(' ');
    } else {
      attributeString = value;
    }
    if (attributeString === undefined || attributeString === null) {
      attributeString = '';
    }
    return attributeString;
  };
  VglGlobalfunctions.addStringFromAttribute = function(domElement, attributeName, value) {
    if (domElement !== undefined && domElement !== null) {
      var attributeString = domElement.getAttribute(attributeName);
      attributeString = VglGlobalfunctions.addStringFromAttributeString(attributeString, value);
      domElement.setAttribute(attributeName, attributeString);
    }
  };
  VglGlobalfunctions.removeStringFromAttributeString = function(attributeString, value) {
    if (attributeString !== undefined && attributeString !== null && attributeString !== '') {
      var attributeStringParts = attributeString.split(' ');
      var attributeStringPartsNew = [];
      for (var i = 0; i < attributeStringParts.length; i++) {
        var attributeStringPart = attributeStringParts[i];
        if (attributeStringPart !== value && attributeStringPart !== '') {
          attributeStringPartsNew.push(attributeStringPart);
        }
      }
      attributeString = attributeStringPartsNew.join(' ');
    }
    if (attributeString === undefined || attributeString === null) {
      attributeString = '';
    }
    return attributeString;
  };
  VglGlobalfunctions.removeStringFromAttribute = function(domElement, attributeName, value) {
    if (domElement !== undefined && domElement !== null) {
      var attributeString = domElement.getAttribute(attributeName);
      attributeString = VglGlobalfunctions.removeStringFromAttributeString(attributeString, value);
      domElement.setAttribute(attributeName, attributeString);
    }
  };

  // -----------------------------------------------------------------------------------------------------------
  // ------------------------------------------------- vglInit -------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------
  var vglInit = function() {
    var propertyWriterManager = null;
    var vglPropertyRuleManager = null;

    var findContextDiv = function() {
      var contextDiv = null;
      var iDocument = VglFinder.getMainIframeDocument();
      var containerDivRight = iDocument.getElementById('ContainerDivRight');

      if (containerDivRight !== undefined && containerDivRight !== null) {
        var divsInContext = containerDivRight.getElementsByTagName('div');
        for (var i = 0; i < divsInContext.length; i++) {
          contextDiv = divsInContext[i];
          if (contextDiv.getAttribute('vglPresent') === 'true') {
            break;
          } else {
            contextDiv = null;
          }
        }
      }
      return contextDiv;
    };

    var initInputFields = function() {
      var iDocument = VglFinder.getMainIframeDocument();
      // var productNameElement = iDocument.getElementById('ctl00_containerPageProductName');
      var productId = 0;

      var customState = UStoreProvider.stateService.store.getState().customState;
      if (customState !== undefined && customState !== null) {
        var currentProduct = customState.currentProduct;
        if (currentProduct !== undefined && currentProduct !== null) {
          productId = currentProduct.FriendlyID;
        }
      }
      if (productId === 0) {
        productId = document.vglHrefHistory.getProductId();
        if (productId === 0) {
          return;
        }
      } else {
        document.vglHrefHistory.setProductId(productId);
      }

      var propListWrapper = iDocument.getElementById('PropListWrapper');
      if (propListWrapper !== undefined && propListWrapper !== null) {

        // -----------------------------------------------------------------------------------------------------------
        // ------------------------------------------- Feldgenerierung dom -------------------------------------------
        // -----------------------------------------------------------------------------------------------------------

        var initPropertyContainer = function(propertyDomElement, propertyContainerClass) {
          var iDocument = VglFinder.getMainIframeDocument();
          var frontEndFieldContainer = iDocument.createElement('div');
          frontEndFieldContainer.setAttribute('id', 'vglDropDown' + propertyDomElement.propertyField.propertyId);
          frontEndFieldContainer.setAttribute('propertyId', propertyDomElement.propertyField.propertyId);
          if (propertyDomElement.visible !== undefined && propertyDomElement.visible === 'none') {
            frontEndFieldContainer.setAttribute('class', 'hidden');
          }
          var frontEndFieldContainerTitle = iDocument.createElement('h3');
          var titleElement = document.createTextNode(propertyDomElement.title);
          frontEndFieldContainerTitle.appendChild(titleElement);

          var titleWrapper = iDocument.createElement('div');
          titleWrapper.setAttribute('class', 'vglPropertyLabel');
          titleWrapper.appendChild(frontEndFieldContainerTitle);

          if ((propertyDomElement.propertyField.helpText !== undefined && propertyDomElement.propertyField.helpText !== '') || propertyDomElement.propertyField.helpTextLink !== undefined) {

            // ?-div
            var helpTextDiv = iDocument.createElement('div');
            helpTextDiv.setAttribute('class', 'vglHelp');
            var helpTextDivQuestionMark = document.createTextNode('?');
            helpTextDiv.appendChild(helpTextDivQuestionMark);
            if (propertyDomElement.propertyField.helpTextLink !== undefined) {
              helpTextDiv.addEventListener('mouseover', (e) => {
                var offsetTop = (propertyDomElement.propertyField.helpTextLink.offsetTop !== undefined ? propertyDomElement.propertyField.helpTextLink.offsetTop : 0);
                var offsetLeft = (propertyDomElement.propertyField.helpTextLink.offsetLeft !== undefined ? propertyDomElement.propertyField.helpTextLink.offsetLeft : 0);
                helpTextDivTextBox.style.top = (helpTextDiv.offsetTop + offsetTop) + 'px';
                helpTextDivTextBox.style.left = (helpTextDiv.offsetLeft + offsetLeft) + 'px';
                helpTextDivTextBox.setAttribute('class', 'vglHelpToolTip-visible vglHelpToolTip-box selected');
              });
              helpTextDiv.addEventListener('mouseout', (e) => {
                helpTextDivTextBox.setAttribute('class', 'vglHelpToolTip-none vglHelpToolTip-box selected');
              });
            }
            titleWrapper.appendChild(helpTextDiv);
            // text-div
            let helpTextDivTextBoxClass = 'vglHelpText';
            var helpTextDivTextBoxText = null;
            if (propertyDomElement.propertyField.helpTextLink !== undefined) {
              helpTextDivTextBoxClass = 'vglHelpToolTip-none vglHelpToolTip-box selected';
              helpTextDivTextBoxText = iDocument.createElement('div');
              var oImg = document.createElement('img');
              oImg.setAttribute('src', propertyDomElement.propertyField.helpTextLink.src);
              oImg.setAttribute('alt', 'bild');
              if (propertyDomElement.propertyField.helpTextLink.width !== undefined) {
                oImg.width = propertyDomElement.propertyField.helpTextLink.width;
              }
              if (propertyDomElement.propertyField.helpTextLink.height !== undefined) {
                oImg.height = propertyDomElement.propertyField.helpTextLink.height;
              }
              helpTextDivTextBoxText.appendChild(oImg);
            } else {
              helpTextDivTextBoxText = document.createTextNode(propertyDomElement.propertyField.helpText);
            }
            var helpTextDivTextBox = iDocument.createElement('div');
            helpTextDivTextBox.setAttribute('class', helpTextDivTextBoxClass);
            helpTextDivTextBox.appendChild(helpTextDivTextBoxText);
            titleWrapper.appendChild(helpTextDivTextBox);
          }

          frontEndFieldContainer.appendChild(titleWrapper);

          var frontEndFieldOptionsContainer = iDocument.createElement('div');
          var propertyContainerClassString = 'propertyContainer';
          if (propertyContainerClass !== undefined) {
            propertyContainerClassString = 'propertyContainer ' + propertyContainerClass;
          }
          frontEndFieldOptionsContainer.setAttribute('class', propertyContainerClassString);
          frontEndFieldContainer.appendChild(frontEndFieldOptionsContainer);

          return {
            frontEndFieldContainer: frontEndFieldContainer,
            frontEndFieldOptionsContainer: frontEndFieldOptionsContainer,
            iDocument: iDocument
          };
        };

        var generateFieldText = function(propertyDomElement) {
          var propertyContainer = initPropertyContainer(propertyDomElement);

          var iDocument = VglFinder.getMainIframeDocument();
          var frontEndInputField = iDocument.createElement('input');
          frontEndInputField.setAttribute('propertyId', propertyDomElement.propertyField.propertyId);
          frontEndInputField.setAttribute('type', 'text');
          frontEndInputField.setAttribute('value', propertyDomElement.propertyField.defaultValue);
          frontEndInputField.setAttribute('class', 'box');

          propertyContainer.frontEndFieldOptionsContainer.appendChild(frontEndInputField);

          // TODO: peto, change-event verfeinern

          frontEndInputField.addEventListener('change', function() {
            propertyDomElement.setValue(this.value);
          });
          return propertyContainer.frontEndFieldContainer;
        };

        // select field
        var generateFieldSelect = function(propertyDomElement) {
          var propertyContainer = initPropertyContainer(propertyDomElement);

          var frontEndInputField = propertyContainer.iDocument.createElement('select');
          frontEndInputField.setAttribute('id', 'vglDropDown' + propertyDomElement.propertyId);
          frontEndInputField.setAttribute('propertyId', propertyDomElement.propertyField.propertyId);
          frontEndInputField.setAttribute('class', 'box span choice');
          for (var key in propertyDomElement.propertyField.options) {
            if (propertyDomElement.propertyField.options.hasOwnProperty(key)) {
              var optionValue = propertyDomElement.propertyField.options[key];
              var optionElement = propertyContainer.iDocument.createElement('option');
              optionElement.setAttribute('value', key);
              var newContent = document.createTextNode(optionValue.value);
              optionElement.appendChild(newContent);

              frontEndInputField.appendChild(optionElement);
            }
          }
          propertyContainer.frontEndFieldOptionsContainer.appendChild(frontEndInputField);

          // set default
          for (var i = 0; i < frontEndInputField.length; i++) {
            var optionElement = frontEndInputField[i];
            var actualValue = (propertyDomElement.value === '' ? propertyDomElement.propertyField.defaultValue
              : propertyDomElement.value);

            if (optionElement.getAttribute('key') === actualValue) {
              optionElement.setAttribute('selected', 'selected');
            } else {
              optionElement.removeAttribute('selected');
            }
          }
          // init event
          frontEndInputField.addEventListener('change', function() {
            propertyDomElement.setValue(this.value);
          });
          return propertyContainer.frontEndFieldContainer;
        };
        generateFieldSelect.getValueFromDom = function(propertyDomElement) {
          var frontEndFieldContainer = propertyDomElement.domElement;
          var frontEndFieldOptionsContainer = frontEndFieldContainer.children[1];
          var frontEndInputField = frontEndFieldOptionsContainer.children[0];

          // set default
          for (var i = 0; i < frontEndInputField.length; i++) {
            var optionElement = frontEndInputField[i];

            if (optionElement.getAttribute('selected') === 'selected') {
              return optionElement.getAttribute('value');
            }
          }
        };
        generateFieldSelect.setValueOnDom = function(propertyDomElement, value) {
          var frontEndFieldContainer = propertyDomElement.domElement;
          var frontEndFieldOptionsContainer = frontEndFieldContainer.children[1];
          var frontEndInputField = frontEndFieldOptionsContainer.children[0];

          // set default
          for (var i = 0; i < frontEndInputField.length; i++) {
            var optionElement = frontEndInputField[i];

            if (optionElement.getAttribute('key') === value) {
              optionElement.setAttribute('selected', 'selected');
            } else {
              optionElement.removeAttribute('selected');
            }
          }
        };

        // radiofield
        var generateFieldRadio = function(propertyDomElement) {
          var propertyContainer = initPropertyContainer(propertyDomElement);
          var optionElement = undefined;
          for (var key in propertyDomElement.propertyField.options) {
            if (propertyDomElement.propertyField.options.hasOwnProperty(key)) {
              var optionValue = propertyDomElement.propertyField.options[key];
              optionElement = propertyContainer.iDocument.createElement('div');
              optionElement.setAttribute('class', 'box span');
              optionElement.setAttribute('key', key);
              optionElement.setAttribute('value', optionValue.value);

              // Zeile 1
              let optionElementP = propertyContainer.iDocument.createElement('span');
              let newContent = document.createTextNode(optionValue.value);
              optionElementP.appendChild(newContent);

              let optionElementP2 = null;
              if (optionValue.value2) {
                // Zeile 2
                optionElementP2 = propertyContainer.iDocument.createElement('span');
                optionElementP2.setAttribute('class', 'vglRadioBoxLine2');
                let newContent2 = document.createTextNode(optionValue.value2);
                optionElementP2.appendChild(newContent2);
              }
              if (optionElementP2 !== null) {
                let optionElementValue = propertyContainer.iDocument.createElement('div');
                optionElementValue.appendChild(optionElementP);
                optionElement.appendChild(optionElementValue);

                let optionElementValue2 = propertyContainer.iDocument.createElement('div');
                optionElementValue2.appendChild(optionElementP2);
                optionElement.appendChild(optionElementValue2);
              } else {
                optionElement.appendChild(optionElementP);
              }
              propertyContainer.frontEndFieldOptionsContainer.appendChild(optionElement);
            }
          }
          // set default
          for (var i = 0; i < propertyContainer.frontEndFieldOptionsContainer.children.length; i++) {
            optionElement = propertyContainer.frontEndFieldOptionsContainer.children[i];
            var actualValue = (propertyDomElement.value === '' ? propertyDomElement.propertyField.defaultValue
              : propertyDomElement.value);

            if (optionElement.getAttribute('key') === actualValue) {
              VglGlobalfunctions.addStringFromAttribute(optionElement, 'class', 'selected');
            } else {
              VglGlobalfunctions.removeStringFromAttribute(optionElement, 'class', 'selected');
            }
          }

          // init event
          for (var i = 0; i < propertyContainer.frontEndFieldOptionsContainer.children.length; i++) {
            optionElement = propertyContainer.frontEndFieldOptionsContainer.children[i];

            optionElement.addEventListener('click', function() {
              var classString = this.getAttribute('class');
              if (!classString.includes('disabled')) {
                for (var i = 0; i < propertyContainer.frontEndFieldOptionsContainer.children.length; i++) {
                  var optionElement = propertyContainer.frontEndFieldOptionsContainer.children[i];

                  if (optionElement.getAttribute('key') === this.getAttribute('key')) {
                    VglGlobalfunctions.addStringFromAttribute(optionElement, 'class', 'selected');
                  } else {
                    VglGlobalfunctions.removeStringFromAttribute(optionElement, 'class', 'selected');
                  }
                }
                propertyDomElement.setValue(this.getAttribute('key'));
              }
            });
          }
          return propertyContainer.frontEndFieldContainer;
        };
        generateFieldRadio.getValueFromDom = function(propertyDomElement) {
          var frontEndFieldContainer = propertyDomElement.domElement;
          var frontEndFieldOptionsContainer = frontEndFieldContainer.children[1];
          for (var i = 0; i < frontEndFieldOptionsContainer.children.length; i++) {
            var optionElement = frontEndFieldOptionsContainer.children[i];

            if (optionElement.getAttribute('class').includes('selected')) {
              return optionElement.getAttribute('value');
            }
          }
          return '';
        };
        generateFieldRadio.setValueOnDom = function(propertyDomElement, value) {
          var frontEndFieldContainer = propertyDomElement.domElement;
          var frontEndFieldOptionsContainer = frontEndFieldContainer.children[1];
          for (var i = 0; i < frontEndFieldOptionsContainer.children.length; i++) {
            var optionElement = frontEndFieldOptionsContainer.children[i];

            if (optionElement.getAttribute('key') === value) {
              VglGlobalfunctions.addStringFromAttribute(optionElement, 'class', 'selected');
            } else {
              VglGlobalfunctions.removeStringFromAttribute(optionElement, 'class', 'selected');
            }
          }
        };
        generateFieldRadio.setVisibleOnDom = function(propertyDomElement, value, visible) {
          var frontEndFieldContainer = propertyDomElement.domElement;
          var frontEndFieldOptionsContainer = frontEndFieldContainer.children[1];
          for (var i = 0; i < frontEndFieldOptionsContainer.children.length; i++) {
            var optionElement = frontEndFieldOptionsContainer.children[i];

            if (optionElement.getAttribute('key') === value) {
              if (visible) {
                VglGlobalfunctions.removeStringFromAttribute(optionElement, 'class', 'hidden');
              } else {
                VglGlobalfunctions.addStringFromAttribute(optionElement, 'class', 'hidden');
              }
            }
          }
        };
        generateFieldRadio.setAccessRightsOnDom = function(propertyDomElement) {
          var frontEndFieldContainer = propertyDomElement.domElement;

          var frontEndFieldOptionsContainer = frontEndFieldContainer.children[1];
          for (var i = 0; i < frontEndFieldOptionsContainer.children.length; i++) {
            var optionElement = frontEndFieldOptionsContainer.children[i];

            for (var key in propertyDomElement.propertyField.options) {
              if (propertyDomElement.propertyField.options.hasOwnProperty(key)) {
                var optionFromEntity = propertyDomElement.propertyField.options[key];

                if (key === optionElement.getAttribute('key')) {
                  if (optionFromEntity.status === 'enabled') {
                    VglGlobalfunctions.removeStringFromAttribute(optionElement, 'class', 'disabled');
                  } else {
                    VglGlobalfunctions.addStringFromAttribute(optionElement, 'class', 'disabled');
                  }
                  if (optionFromEntity.visible !== undefined) {
                    if (optionFromEntity.visible === 'true' || optionFromEntity.visible === true) {
                      VglGlobalfunctions.removeStringFromAttribute(optionElement, 'class', 'hidden');
                    } else {
                      VglGlobalfunctions.addStringFromAttribute(optionElement, 'class', 'hidden');
                    }
                  }
                  break;
                }
              }
            }
          }
        };

        var setBussyStatus = function(status) {
          var iDocument = VglFinder.getMainIframeDocument();
          var btnNext = iDocument.getElementById('ctl00_cphMainContentFooter_WizardStepsNextPrev1_ButtonNext');
          var priceElement = iDocument.getElementById('vglPriceElement');
          var priceElementInProgress = iDocument.getElementById('vglPriceElementInProgress');

          if (btnNext !== undefined && btnNext !== null) {
            if (status === 'bussy') {
              VglGlobalfunctions.addStringFromAttribute(btnNext, 'class', 'background-color-ddd');
              VglGlobalfunctions.addStringFromAttribute(priceElement, 'class', 'hidden');
              VglGlobalfunctions.removeStringFromAttribute(priceElementInProgress, 'class', 'hidden');

              btnNext.setAttribute('vglStatus', 'bussy');
              btnNext.addEventListener('click', function(event) {
                var vglStatus = this.getAttribute('vglStatus');

                if (vglStatus === 'bussy') {
                  event.preventDefault();
                }
              });
            } else {
              btnNext.setAttribute('vglStatus', 'ready');
              VglGlobalfunctions.removeStringFromAttribute(btnNext, 'class', 'background-color-ddd');
              VglGlobalfunctions.removeStringFromAttribute(priceElement, 'class', 'hidden');
              VglGlobalfunctions.addStringFromAttribute(priceElementInProgress, 'class', 'hidden');
            }
          }
        };

        // -----------------------------------------------------------------------------------------------------------
        // --------------------------------------------------- init --------------------------------------------------
        // -----------------------------------------------------------------------------------------------------------

        // wenn vorherige seite category ist, dann alles neu initialisieren
        if (document.vglHrefHistory.getSecondLast().includes('/product/')) {
          propertyWriterManager = null;
          vglPropertyRuleManager = null;
        }

        var fieldsDef = [];
        if (propertyWriterManager === undefined || propertyWriterManager === null) {
          var propertyEntity = new VglPropertyEntity();

          // --------------------------------------------- define - begin --------------------------------------------
          var productIdTemp = productId;
          var openDialogButtonId = '';
          switch (productId) {
            case Number(vglProductIds.broschure.productId):
              // broschüre
              openDialogButtonId = vglProductIds.broschure.fields.umfang.propertyId;
              fieldsDef = [new BaseConfigFieldTemplateRadio(vglProductIds.broschure.fields.umfang.propertyId,
                generateFieldSelect, t.get().label_Umfang, '8', {
                  '8': {value: '8-' + t.get().label_seitig, status: 'enabled'},
                  '12': {value: '12-' + t.get().label_seitig, status: 'enabled'},
                  '16': {value: '16-' + t.get().label_seitig, status: 'enabled'},
                  '20': {value: '20-' + t.get().label_seitig, status: 'enabled'},
                  '24': {value: '24-' + t.get().label_seitig, status: 'enabled'},
                  '28': {value: '28-' + t.get().label_seitig, status: 'enabled'},
                  '32': {value: '32-' + t.get().label_seitig, status: 'enabled'},
                  '36': {value: '36-' + t.get().label_seitig, status: 'enabled'},
                  '40': {value: '40-' + t.get().label_seitig, status: 'enabled'},
                  '44': {value: '44-' + t.get().label_seitig, status: 'enabled'},
                  '48': {value: '48-' + t.get().label_seitig, status: 'enabled'}
                }),
                new BaseConfigFieldTemplateRadio(vglProductIds.broschure.fields.format.propertyId, generateFieldRadio,
                  t.get().label_Format, 'A4', {
                    'A4': {value: 'A4', status: 'enabled'}, 'A5': {value: 'A5', status: 'enabled'}
                  }),
                new DefaulOrientation(vglProductIds.broschure.fields.ausrichtung.propertyId, generateFieldRadio),
                new BaseConfigFieldTemplateRadio(vglProductIds.broschure.fields.papier.propertyId, generateFieldRadio,
                  t.get().label_Papier, 'P0001', {
                    'P0001': vglPaper.p0001(),
                    'P0002': vglPaper.p0002(),
                    'P0004, P0003': {value: t.get().label_ungestrichen + ' 240g/m², 120g/m²', status: 'enabled', value2: 'Lessebo 1.3 Rough White'},
                    'P0006, P0005': {value: t.get().label_gestrichen + ' 250g/m², 135g/m²', status: 'enabled', value2: 'Pureprint matt'}
                  }), new DefaulQty250To5000(vglProductIds.broschure.fields.auflage.propertyId, generateFieldSelect)];
              break;
            case Number(vglProductIds.visitenkartenSamen.productId):
              // samen-visitenkarte
              openDialogButtonId = vglProductIds.visitenkartenSamen.fields.auflage.propertyId;
              fieldsDef = [new DefaulQty250To5000(vglProductIds.visitenkartenSamen.fields.auflage.propertyId,
                generateFieldSelect),
                new DefaulPages(vglProductIds.visitenkartenSamen.fields.pages.propertyId, generateFieldRadio,
                  undefined, 'none'),
                new DefaulColor(vglProductIds.visitenkartenSamen.fields.farbigkeit.propertyId, generateFieldRadio)];
              break;
            case Number(vglProductIds.visitenkartenC2c.productId):
              // visitenkarte c2c
              openDialogButtonId = vglProductIds.visitenkartenC2c.fields.auflage.propertyId;
              fieldsDef = [new BaseConfigFieldTemplateRadio(vglProductIds.visitenkartenC2c.fields.auflage.propertyId,
                generateFieldSelect, t.get().label_Auflage, '100', {
                  '100': {value: '100', status: 'enabled'},
                  '250': {value: '250', status: 'enabled'},
                  '500': {value: '500', status: 'enabled'},
                  '750': {value: '750', status: 'enabled'},
                  '1000': {value: '1000', status: 'enabled'},
                  '1500': {value: '1500', status: 'enabled'},
                  '2000': {value: '2000', status: 'enabled'}
                }),
                new DefaulPages(vglProductIds.visitenkartenC2c.fields.pages.propertyId, generateFieldRadio, undefined,
                  'none'),
                new DefaulColor(vglProductIds.visitenkartenC2c.fields.farbigkeit.propertyId, generateFieldRadio)];
              break;
            case Number(vglProductIds.briefpapier.productId):
              // Briefpapier
              openDialogButtonId = vglProductIds.briefpapier.fields.auflage.propertyId;
              fieldsDef = [new DefaulQty500To10000(vglProductIds.briefpapier.fields.auflage.propertyId,
                generateFieldSelect, 'smal'),
                new DefaulPages(vglProductIds.briefpapier.fields.pages.propertyId, generateFieldRadio, undefined,
                  'none'),
                new DefaulColor(vglProductIds.briefpapier.fields.farbigkeit.propertyId, generateFieldRadio)];
              break;
            case Number(vglProductIds.korrespondenzkarten.productId):
              // Korrespondenzkarten
              openDialogButtonId = vglProductIds.korrespondenzkarten.fields.auflage.propertyId;
              fieldsDef = [new DefaulQty500To10000(vglProductIds.korrespondenzkarten.fields.auflage.propertyId,
                generateFieldSelect, 'smal'),
                new DefaulPages(vglProductIds.korrespondenzkarten.fields.pages.propertyId, generateFieldRadio,
                  undefined, 'none'),
                new DefaulColor(vglProductIds.korrespondenzkarten.fields.farbigkeit.propertyId, generateFieldRadio)];
              break;
            case Number(vglProductIds.postkartenSamen.productId):
              // samen-postkarten
              openDialogButtonId = vglProductIds.postkartenSamen.fields.auflage.propertyId;
              fieldsDef = [new BaseConfigFieldTemplateRadio(vglProductIds.postkartenSamen.fields.auflage.propertyId,
                generateFieldSelect, t.get().label_Auflage, '250', {
                  '250': {value: '250', status: 'enabled'},
                  '500': {value: '500', status: 'enabled'},
                  '750': {value: '750', status: 'enabled'},
                  '1000': {value: '1000', status: 'enabled'},
                  '2000': {value: '2000', status: 'enabled'}
                })];
              break;
            case Number(vglProductIds.postkartenC2c.productId):
              // postkarten c2c
              openDialogButtonId = vglProductIds.postkartenC2c.fields.auflage.propertyId;
              fieldsDef = [new BaseConfigFieldTemplateRadio(vglProductIds.postkartenC2c.fields.auflage.propertyId,
                generateFieldSelect, t.get().label_Auflage, '250', {
                  '250': {value: '250', status: 'enabled'},
                  '500': {value: '500', status: 'enabled'},
                  '750': {value: '750', status: 'enabled'},
                  '1000': {value: '1000', status: 'enabled'},
                  '2000': {value: '2000', status: 'enabled'},
                  '3000': {value: '3000', status: 'enabled'},
                  '5000': {value: '5000', status: 'enabled'},
                  '7500': {value: '7500', status: 'enabled'},
                  '10000': {value: '10000', status: 'enabled'},
                  '15000': {value: '15000', status: 'enabled'}
                }), new DefaulColor(vglProductIds.postkartenC2c.fields.farbigkeit.propertyId, generateFieldRadio, {
                '4/1': {value: t.get().label_blackWhite, status: 'enabled'},
                '4/4': {value: t.get().label_color, status: 'enabled'}
              }, '4/1', t.get().help_colorFrontBack)];
              break;
            case Number(vglProductIds.flyerC2c.productId):
              // flyer c2c
              openDialogButtonId = vglProductIds.flyerC2c.fields.format.propertyId;
              fieldsDef = [new DefaulFormat(vglProductIds.flyerC2c.fields.format.propertyId, generateFieldRadio,
                {'A6/5': {value: 'A6/5', status: 'enabled'}, 'A7': {value: 'A7', status: 'enabled'}}),
                new DefaulOrientation(vglProductIds.flyerC2c.fields.ausrichtung.propertyId, generateFieldRadio),
                new BaseConfigFieldTemplateRadio(vglProductIds.flyerC2c.fields.papier.propertyId, generateFieldRadio,
                  t.get().label_Papier, 'P0011', {
                    'P0011': vglPaper.p0011(true),
                    'P0014': vglPaper.p0014(false),
                    'P0019': vglPaper.p0019(),
                    'P0018': vglPaper.p0018(true),
                    'P0023': vglPaper.p0023(false),
                    'P0005': vglPaper.p0005(),
                    'P0013': vglPaper.p0013(true),
                    'P0016': vglPaper.p0016(false),
                    'P0010': vglPaper.p0010()
                  }), new DefaulQty500To10000(vglProductIds.flyerC2c.fields.auflage.propertyId, generateFieldSelect),
                new DefaulColor(vglProductIds.flyerC2c.fields.farbigkeit.propertyId, generateFieldRadio)];
              break;
            case Number(vglProductIds.flyerFalz.productId):
              // falz-flyer 4-Seitig
              openDialogButtonId = vglProductIds.flyerFalz.fields.format.propertyId;
              fieldsDef = [new DefaulFormat(vglProductIds.flyerFalz.fields.format.propertyId, generateFieldRadio,
                  {'A6/5': {value: 'A6/5', status: 'enabled'}}),
                new DefaulOrientation(vglProductIds.flyerFalz.fields.ausrichtung.propertyId, generateFieldRadio),
                new BaseConfigFieldTemplateRadio(vglProductIds.flyerFalz.fields.papier.propertyId, generateFieldRadio,
                  t.get().label_Papier, 'P0014', {
                    'P0014': vglPaper.p0014(true),
                    'P0019': vglPaper.p0019(true),
                    'P0018': vglPaper.p0018(false),
                    'P0021': vglPaper.p0021(false)
                  }),
                new DefaulQty500To10000(vglProductIds.flyerFalz.fields.auflage.propertyId, generateFieldSelect)];
              break;
            case Number(vglProductIds.flyerFalz6.productId):
              // falz-flyer 6-Seitig
              openDialogButtonId = vglProductIds.flyerFalz6.fields.format.propertyId;
              fieldsDef = [new DefaulFormat(vglProductIds.flyerFalz6.fields.format.propertyId, generateFieldRadio,
                  {'A6/5': {value: 'A6/5', status: 'enabled'}}),
                new DefaulOrientation(vglProductIds.flyerFalz6.fields.ausrichtung.propertyId, generateFieldRadio),
                new BaseConfigFieldTemplateRadio(vglProductIds.flyerFalz6.fields.papier.propertyId,
                  generateFieldRadio, t.get().label_Papier, 'P0014', {
                    'P0014': vglPaper.p0014(true),
                    'P0019': vglPaper.p0019(true),
                    'P0018': vglPaper.p0018(false),
                    'P0021': vglPaper.p0021(false)
                  }), new FoldF06(vglProductIds.flyerFalz6.fields.fold.propertyId, generateFieldRadio, {src: '/ustore/images/_eigene_Skins/pureprint_shop/img/Falzarten_Erklaerung_frde.jpg', offsetTop: -400, width: 320}),
                new DefaulQty500To10000(vglProductIds.flyerFalz6.fields.auflage.propertyId, generateFieldSelect)];
              break;
            case Number(vglProductIds.plakat.productId):
              // plakat
              openDialogButtonId = vglProductIds.plakat.fields.format.propertyId;
              fieldsDef = [new DefaulFormat(vglProductIds.plakat.fields.format.propertyId, generateFieldRadio, null, {
                'A2': {value: 'A2', status: 'enabled'},
                'A3': {value: 'A3', status: 'enabled'},
                'A4': {value: 'A4', status: 'enabled'}
              }), new BaseConfigFieldTemplateRadio(vglProductIds.plakat.fields.papier.propertyId, generateFieldRadio,
                t.get().label_Papier, 'P0011', {
                  'P0011': vglPaper.p0011(), 'P0019': vglPaper.p0019()
                }),
                new DefaulQty500To10000(vglProductIds.plakat.fields.auflage.propertyId, generateFieldSelect, 'smal')];
              break;
            case Number(vglProductIds.tischset.productId):
              // tischset
              openDialogButtonId = vglProductIds.tischset.fields.auflage.propertyId;
              fieldsDef = [new DefaulQty500To10000(vglProductIds.tischset.fields.auflage.propertyId,
                generateFieldSelect, 'smal')];
              break;
            case Number(vglProductIds.popcorn.productId):
              // popcorn
              openDialogButtonId = vglProductIds.popcorn.fields.auflage.propertyId;
              fieldsDef = [new DefaulQty250To10000(vglProductIds.popcorn.fields.auflage.propertyId,
                generateFieldSelect)];
              break;
            case Number(vglProductIds.klatschfaecher.productId):
              // klatschfaecher
              openDialogButtonId = vglProductIds.klatschfaecher.fields.auflage.propertyId;
              fieldsDef = [new DefaulQty250To10000(vglProductIds.klatschfaecher.fields.auflage.propertyId,
                generateFieldSelect),
                new DefaulColor(vglProductIds.klatschfaecher.fields.farbigkeit.propertyId, generateFieldRadio)];
              break;
            default:
              productIdTemp = 0;
              break;
          }
          // ---------------------------------------------- define - end ---------------------------------------------

          // init - fieldObjects
          for (var i = 0; i < fieldsDef.length; i++) {
            var fieldDef = fieldsDef[i];

            var propertyField = new VglPropertyField(fieldDef.propertyId, fieldDef.defaultValue, fieldDef.options,
              {modificationFieldDomId: fieldDef.modificationFieldDomId}, fieldDef.helpText, fieldDef.helpTextLink);
            propertyField.status = VglSendStatus.modified;
            propertyField.addPropertyDomElement(
              new VglPropertyDomElement(fieldDef.title, fieldDef.initialisationFunction, fieldDef.setValueOnDom,
                fieldDef.setVisibleOnDom, fieldDef.getValueFromDom, fieldDef.setAccessRightsOnDom, fieldDef.visible));
            propertyEntity.addField(propertyField);
          }
          propertyWriterManager = new VglPropertyWriterManager(propertyEntity,
            new VglPropertyWriterDialog(openDialogButtonId, function(status) {
              setBussyStatus(status);
            }), productIdTemp);

          var iframe = VglFinder.getMainIframe();
          iframe.propertyWriterManager = propertyWriterManager; // damit beim unmounten reset aufgerufen werden kann

          vglPropertyRuleManager = new VglPropertyRuleManager(productId);
          propertyEntity.addOnInitDoneListener(vglPropertyRuleManager.validate);
          propertyEntity.addOnValueChangeListener(vglPropertyRuleManager.validate);
          propertyEntity.addOnValueChangeListener(propertyWriterManager.onChange);
          document.vglHrefHistory.clear();
        }

        var contextDiv = findContextDiv();
        if (contextDiv === null) {
          contextDiv = iDocument.createElement('div');
          contextDiv.setAttribute('vglPresent', 'true');

          // init container right
          var containerDivRight = iDocument.getElementById('ContainerDivRight');

          if (propertyWriterManager !== undefined && propertyWriterManager !== null &&
            propertyWriterManager.productId === productId) {
            containerDivRight.appendChild(contextDiv);

            // init label-area
            var labelBoxElement = iDocument.createElement('div');
            labelBoxElement.setAttribute('class', 'vglLabelBox');
            contextDiv.appendChild(labelBoxElement);
            var titleElement = iDocument.createElement('div');
            titleElement.setAttribute('id', 'vglTitleElement');
            var titleUstoreElement = iDocument.getElementById('ctl00_containerPageProductName');
            var titleElementText = document.createTextNode(titleUstoreElement.textContent);
            titleElement.appendChild(titleElementText);
            labelBoxElement.appendChild(titleElement);

            var preisElement = iDocument.createElement('div');
            preisElement.setAttribute('id', 'vglPriceElement');
            preisElement.setAttribute('class', 'hidden');
            labelBoxElement.appendChild(preisElement);

            var preisElementInProgress = iDocument.createElement('div');
            preisElementInProgress.setAttribute('id', 'vglPriceElementInProgress');
            preisElementInProgress.setAttribute('class', '');
            var preisElementInProgressText = document.createTextNode(t.get().label_calculate);
            preisElementInProgress.appendChild(preisElementInProgressText);
            labelBoxElement.appendChild(preisElementInProgress);

            propertyWriterManager.init();
            propertyWriterManager.appendPropertiesToRootNode(contextDiv);
            setBussyStatus('ready');
            propertyWriterManager.forceSendAll();
          }
        }

        // preis übernehmen
        var preisSpanElement = iDocument.getElementById('spnTotalPrice');
        var priceValueElement = iDocument.getElementById('vglPriceElement');
        if (priceValueElement !== undefined && priceValueElement !== null) {
          priceValueElement.textContent = preisSpanElement.textContent;
        }
        //console.log('--- initInputFields ---');
      }

      var setPageValue = function(propertyWriterManager, value) {
        if (propertyWriterManager !== null) {
          var fieldId = '';

          // --------------------------------------------- define - begin --------------------------------------------
          switch (productId) {
            case Number(vglProductIds.visitenkartenSamen.productId):
              // samen-visitenkarte
              fieldId = vglProductIds.visitenkartenSamen.fields.pages.propertyId;
              break;
            case Number(vglProductIds.visitenkartenC2c.productId):
              // visitenkarte c2c
              fieldId = vglProductIds.visitenkartenC2c.fields.pages.propertyId;
              break;
            // case 22732:
            //   // Briefpapier
            //   fieldId = '133115';
            //   break;
          }
          // ---------------------------------------------- define - end ---------------------------------------------

          var field = propertyWriterManager.propertyEntity.getField(fieldId);
          if (field !== null && field.value !== value && value !== 0) {
            field.setValue(value);
            propertyWriterManager.send();
            //field.domElement.setValueOnDom(value);
          }
        }
      };

      var countPages = function(propertyWriterManager) {
        var numOfPages = 0;
        var subDocumentContainerDiv = iDocument.getElementsByClassName('DocumentContainerDiv');
        if (subDocumentContainerDiv !== undefined && subDocumentContainerDiv !== null) {
          for (var i = 0; i < subDocumentContainerDiv.length; i++) {
            var temp = subDocumentContainerDiv[i];
            var value = parseInt(temp.getAttribute('pages'));
            numOfPages += value;
          }
          setPageValue(propertyWriterManager, numOfPages);
        }
      };
      countPages(propertyWriterManager);
    };

    var mainLoop = function() {
      //initStyle();
      setTimeout(function() {
        if (mainLoopInterval === null) {
          //console.log('--- setInterval ---');
          mainLoopInterval = setInterval(initInputFields, 1000);
        }
      }, 1);
    };
    mainLoopInterval = null;
    var iframe = VglFinder.getMainIframe();
    if (iframe.getAttribute('vglPresent') !== 'true') {
      iframe.setAttribute('vglPresent', 'true');
      iframe.addEventListener('load', function() {
        mainLoop();
      });
    }
  };



  return (
    <Layout {...props}>
      <div className="iframe-container" ref={e => legacyIframeHandler.iframeContainer = e} />
    </Layout>
  )
}

export default Legacy
